<template>
  <div class="edit-rules">
    <div class="edit-form h-100">
      <CCardHeader>
        <div class="d-flex justify-content-between align-items-center">
          <strong class="title">Edit Rules</strong>
          <div class="message" v-if="!isRulesDataSaved">Data not saved!</div>
          <CButton
            class="action-create-button zq--responsive-button__common"
            type="submit"
            @click="updateRules"
          >
            Update Rules
          </CButton>
        </div>
      </CCardHeader>
      <div class="form-content">
        <!-- Actions  -->
        <div class="rules-header-actions">
          <div class="rule-actions mb-2">
            <ClCustomButton class="mr-2 mb-1" icon @click="toggleJsonView">
              <template #right-icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g fill-rule="evenodd">
                    <g fill-rule="nonzero">
                      <g>
                        <g>
                          <g>
                            <path
                              d="M14.371 6.072c.477.19.726.705.595 1.189l-.038.11-4 10c-.205.513-.787.763-1.3.557-.476-.19-.725-.705-.594-1.189l.038-.11 4-10c.205-.513.787-.763 1.3-.557zm4.325.21l.085.093 4 5c.265.332.29.791.072 1.147l-.072.103-4 5c-.345.431-.974.5-1.406.156-.398-.319-.488-.88-.228-1.303l.072-.103L20.72 12l-3.5-4.375c-.318-.398-.283-.965.063-1.321l.093-.085c.398-.318.965-.283 1.321.063zM6.625 6.22c.398.319.488.88.228 1.303l-.072.103L3.28 12l3.5 4.375c.32.398.284.965-.062 1.321l-.093.085c-.398.318-.965.283-1.321-.063l-.085-.093-4-5c-.265-.332-.29-.791-.072-1.147l.072-.103 4-5c.345-.431.974-.5 1.406-.156z"
                              transform="translate(-693 -352) translate(136 344) translate(549) translate(8 8)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </template>
            </ClCustomButton>
            <!--     RESET     -->
            <ClCustomButton
              v-if="!isTemplatesTable"
              @click="handleResetRules"
              :disabled="isFieldDisabled"
              :text="$t('buttons.clear')"
              class="mr-2 mb-1"
            />
            <ClCustomButton
              v-else
              @click="closeTemplatesTable"
              :disabled="isFieldDisabled"
              :text="$t('buttons.goBack')"
              class="mr-2 mb-1"
            />
            <!--    Save as Template      -->
            <ClCustomButton
              :disabled="isFieldDisabled"
              :text="$t('buttons.SaveAsTemplate')"
              class="mr-2 mb-1"
              @click="openSaveAsTemplateModal"
            />
            <!--    Load Template      -->
            <ClCustomButton
              :disabled="isFieldDisabled"
              :text="$t('buttons.LoadTemplate')"
              class="mr-2"
              @click="openTemplatesTable"
            />
            <!--    Expand       -->
            <ClCustomButton class="mr-2 rules-expand-btn" icon>
              <template #right-icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g fill-rule="nonzero" stroke-width="2">
                      <g>
                        <g>
                          <g>
                            <path d="M4 20L4 16M16 4L20 4M20 8L20 4M4 20L10 14M4 20L8 20M14 10L20 4"
                              transform="translate(-1192 -352) translate(136 344) translate(1048) translate(8 8)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </template>
            </ClCustomButton>
          </div>
        </div>
        <TemplatesTable
          v-if="isTemplatesTable"
          @getFromTemplate="getFromTemplate"
          :context="context"
        />
        <CCard class="zq--wizard-card" v-if="isReady && !isTemplatesTable">
          <CCardBody class="d-flex">
            <div class="field-paper" v-if="isFieldDisabled"></div>
            <RulesEngine
              @change-data="onChange"
              :query="query"
              :routines="routines"
              :rules="rules"
              ref="rulesEngine"
              :key="rulesEngineKey"
              :isJsonView="isJsonView"
            />
          </CCardBody>
        </CCard>
        <PreviewSpiner v-else-if="!isTemplatesTable" />
        <Modal
          :modalShow="isSaveAsTemplateModal"
          :title="'Save As Template'"
          :messageGeneral="'Enter Template Name'"
          :successBtnLabel="'Save'"
          @doFunction="saveAsTemplate"
          @toggle-modal="closeModal"
        >
          <template #body>
            <CInput
              v-model="templateName"
              :placeholder="'Template Name'"
              :isrequired="true"
              add-input-classes="col-sm-12"
              name="templateName"
              @input="trimTemplateName"
            />
          </template>
        </Modal>
        <Modal
          :modalShow="clearModal"
          :messageGeneral="`This will clear all rules  for this ${context}`"
          :title="'Clear Rules'"
          @doFunction="resetRules"
          v-on:toggle-modal="closeClearModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {isAchFieldDisabled, isCompFieldDisabled} from "@/utils/validationUtils";
import {formConfig} from '@/config';
import ClCustomButton from '@/shared/components/formComponents/ClCustomButton';
import PreviewSpiner from '@/shared/UI/Spiner';
import {achievements} from "@/config/descriptions/achievements";
import {achievementQuery} from "@/helpers/rules/achievementQuery";
import {defaultRoutines} from "@/helpers/rules/routines";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
import {mapActions} from 'vuex';
import {cloneDeep} from "lodash";
import {competitionQuery} from "@/helpers/rules/competitionQuery";
import {contestQuery} from "@/helpers/rules/contestQuery";
import Modal from '@/shared/UI/Modal';
import TemplatesTable from '@/shared/components/supportModels/rules/TemplatesTable';

export default {
  name: 'EditRules',
  components: {
    ClCustomButton,
    PreviewSpiner,
    Modal,
    TemplatesTable,
  },
  props: {
    status: {
      type: String,
      default: 'Ready'
    },
    isRulesDropDown: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: String,
      default: 'Ach'
    },
    stepNumber: {
      type: Number,
      default: 6
    },
    context: {
      type: String,
      default: ""
    },
    entityId: {
      type: String,
      default: '',
    },
    notSavedRulesData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      rulesType: '',
      rulesOption: [
        {title: 'Event points', img: 'fa fa-exclamation-circle', color: '#eaa642'},
        {title: 'Start the contests', img: 'fa fa-check-circle', color: '#97c664'},
        {title: 'Finish the contests', img: 'fa fa-check-circle', color: '#97c664'},
        {title: 'Finalise the contests', img: 'fa fa-check-circle', color: '#97c664'},
        {title: 'Custom points', img: 'fa fa-check-circle', color: '#348ee3'},
        {title: 'Entrant progression', img: 'fa fa-check-circle', color: '#348ee3'},
        {title: 'Cancel the contests', img: 'fa fa-check-circle', color: '#348ee3'},
      ],
      descriptions: {
        rules: achievements.list.create.rules.rules,
      },
      texts: {
        ...achievementsTexts
      },
      routines: defaultRoutines,
      query: {},
      rules: {},
      startQuery: {},
      startRules: {},
      rulesEngineKey: 0,
      isJsonView: false,
      isReady: false,
      clearModal: false,
      rulesData: {},
      entityType: '',
      isRulesDataSaved: true,
      isTemplatesTable: false,
      isSaveAsTemplateModal: false,
      templateName: '',
      templateData: {},
      ruleId: null,
    }
  },
  computed: {
    isFieldDisabled() {
      if (this.pageType === 'Ach') {
        return isAchFieldDisabled(this.status)
      }
      return isCompFieldDisabled(this.status);
    },
  },
  created() {
    this.initialize();
  },
  destroyed() {
    if (!this.isRulesDataSaved) {
      this.$emit('notSavedRulesData', {query: this.rulesData, rules: this.rules, ruleId: this.ruleId, entityType: this.entityType});
    }
  },
  methods: {
    ...mapActions('rules', ['handleGetRuleSchemas', 'handleGetRulesByQuery', 'handleUpdateRules']),
    ...mapActions('fileRepositories', ['handleGetFileRepositories', 'handleGetFileRepositoriesByQuery']),
    ...mapActions('files', ['handleSaveTemplates']),
    async initialize() {
      if (this.context === 'competition') {
        this.startQuery = cloneDeep(competitionQuery);
      }
      if (this.context === 'contest') {
        this.startQuery = cloneDeep(contestQuery);
      }
      if (this.context === 'achievement') {
        this.startQuery = cloneDeep(achievementQuery);
      }

      await this.handleGetRuleSchemas({context: this.context})
        .then((items) => {
          const schemas = cloneDeep(items[0]);
          const pointsIdx = schemas.actions.findIndex(item => item.action === 'contest.points');

          if (pointsIdx !== -1) {
            const points = schemas.actions[pointsIdx];
            schemas.actions.splice(pointsIdx, 1);
            schemas.actions.unshift(points);
          }

          this.startRules = cloneDeep(schemas);

          if (Object.keys(this.notSavedRulesData).length && Object.keys(this.notSavedRulesData.rules).length) {
            const schemas = cloneDeep(this.notSavedRulesData.rules);
            const pointsIdx = schemas.actions.findIndex(item => item.action === 'contest.points');

            if (pointsIdx !== -1) {
              const points = schemas.actions[pointsIdx];
              schemas.actions.splice(pointsIdx, 1);
              schemas.actions.unshift(points);
            }
            this.rules = cloneDeep(schemas);
          } else {

            const schemas = cloneDeep(items[0]);
            const pointsIdx = schemas.actions.findIndex(item => item.action === 'contest.points');

            if (pointsIdx !== -1) {
              const points = schemas.actions[pointsIdx];
              schemas.actions.splice(pointsIdx, 1);
              schemas.actions.unshift(points);
            }
            this.rules = cloneDeep(schemas);

            const routines = cloneDeep(schemas.routines);
            if (routines && routines.length) {
              this.routines = routines.map(routine => {
                if (routine.arguments[0].macros && routine.arguments[0].macros.length) {
                  const macros = [];
                  for (let i = 0; i < routine.arguments[0].macros.length; i++) {
                    macros.push({ scope: '$variable', value: routine.arguments[0].macros[i] });
                  }
                  routine.arguments[0].macros = macros;
                }

                if (routine.parameter.macros && routine.parameter.macros.length) {
                  const macros = [];
                  for (let i = 0; i < routine.parameter.macros.length; i++) {
                    macros.push({ scope: '$variable', value: routine.parameter.macros[i] });
                  }
                  routine.parameter.macros = macros;
                }

                return routine;
              });
            }
          }
        });

      await this.handleGetRulesByQuery(
        {
          queryRequest: {
            should: [{
              queryField: 'entityId',
              queryValues: [this.entityId]
            }],
            shouldMatch: 1
          }
        }
      ).then(data => {
        if (data && data.length) {
          this.ruleId = data[0].id;
          this.entityType = data[0].entityType;
          data.forEach(action => {
            for (let key in action.rules) {
              if (action.rules[key] === null) {
                if (key === 'rules') {
                  action.rules[key] = [];
                } else {
                  action.rules[key] ='';
                }
              }
            }
            this.query[action.action] = action.rules;
            this.query[action.action].id = action.id;
            this.clearNullRules(this.query[action.action].rules);
          });
        } else {
          if (this.context === 'competition') {
            this.query = cloneDeep(competitionQuery);
          }
          if (this.context === 'contest') {
            this.query = cloneDeep(contestQuery);
          }
          if (this.context === 'achievement') {
            this.query = cloneDeep(achievementQuery);
          }
        }

        this.templateData = cloneDeep(this.query);

        if (Object.keys(this.notSavedRulesData).length && Object.keys(this.notSavedRulesData.query).length) {
          for (const key in this.notSavedRulesData.query) {
            this.query[key] = cloneDeep(this.notSavedRulesData.query[key]);
          }
          this.rulesData = cloneDeep(this.query);
          this.isRulesDataSaved = false;
        }
      });

      this.isReady = true;
    },
    toggleJsonView() {
      this.isJsonView = !this.isJsonView;
    },
    addRuleSet() {
      this.$refs.rulesEngine.query.push({
        mustMatchAll: "TRUE",
        mustEvaluateTo: 'TRUE',
        rules: [],
        then: [],
      })
    },
    handleResetRules() {
      this.clearModal = true;
    },
    resetRules() {
      const tabs = this.$refs.rulesEngine.$el.querySelectorAll('.tabs-component-panel');
      if (tabs.length) {
        tabs.forEach(tab => {
          const topLevelGroup = tab.querySelector('.rew-group.rew-group--depth-0');
          const clearButton = topLevelGroup.querySelector('.rew-icon-button.rew-icon-button--danger');
          if (clearButton) {
            clearButton.click();
          }
        })
      }
      this.clearModal = false;
    },
    openSaveAsTemplateModal() {
      this.isSaveAsTemplateModal = true;
    },
    openTemplatesTable() {
      this.isTemplatesTable = true;
    },
    closeTemplatesTable() {
      this.isTemplatesTable = false;
    },
    closeClearModal() {
      this.clearModal = false;
    },
    closeModal() {
      this.isSaveAsTemplateModal = false;
    },
    onChange(data) {
      for (let key in data) {
        if (data[key]) {
          this.templateData[key] = data[key];
          this.rulesData[key] = data[key];
        }
      }
      this.isRulesDataSaved = false;
      this.$emit('isRulesDataSaved', false)
    },
    async saveAsTemplate() {
      if (!this.templateName) {
        console.error('templateName error')
      } else if (!Object.keys(this.templateData).length) {
        console.error('templateData empty')
      } else {
        const repository = await this.handleGetFileRepositoriesByQuery({
          queryRequest: {
            must: [{
              queryField: 'name',
              queryValues: ['system-resources'],
            }]
          }
        });

        const templateString = JSON.stringify(this.templateData)

        const folderName = this.getTemplateFolderName()

        const payload = {
          tagsArray: 'rules',
          parentFolderPath: folderName,
          repositoryId: repository[0].id,
          templateToSave: templateString,
          templateName: this.templateName,
        };

        await this.handleSaveTemplates(payload);
      }
      this.isSaveAsTemplateModal = false;
    },
    trimTemplateName(title) {
      this.templateName = title.replaceAll(' ', '');
    },
    getTemplateFolderName() {
      let folderName = '';

      switch (this.context) {
        case 'competition':
          folderName = '/rules/competitions';
          break;
        case 'contest':
          folderName = '/rules/contests';
          break;
        case 'achievement':
          folderName = '/rules/achievements';
          break;
      }

      return folderName;
    },
    getFromTemplate(template) {
      this.isTemplatesTable = false;
      if (Object.keys(template).length) {
        for (const [key, value] of Object.entries(template)) {
          if (template) {
            const id = this.query[key].id;
            this.query[key] = value;
            this.query[key].id = id;
          }
        }
        this.templateData = cloneDeep(template);
        this.rulesData = cloneDeep(this.query);
        this.isRulesDataSaved = false;
        this.$emit('updateDataFromTemplate', template);
      }
    },
    clearNullRules(rules) {
      if (!rules || !rules.length) return [];

      return rules.map(rule => {
        if (!rule) return [];

        if (rule.type === 'condition') {
          if (!rule.rules) {
            rule.rules = [];
          } else {
            rule.rules = this.clearNullRules(rule.rules);
          }
        }

        return rule;
      });
    },
    getRules() {
      let rules = [];
      for (const action in this.rulesData) {
        if (this.rulesData[action].then && this.rulesData[action].then.length) {
          this.rulesData[action].then = this.rulesData[action].then.map(t => {
            if (!t.arguments) {t.arguments = []}
            return t;
          })
        }

        this.clearNullRules(this.rulesData[action].rules);

        let ruleObject = {};

        ruleObject.id = this.rulesData[action].id;
        // delete this.rulesData[action].id;
        ruleObject.rules = {};
        ruleObject.rules.entityId = this.entityId;
        ruleObject.rules.action = action;
        ruleObject.rules.context = this.context;
        ruleObject.rules.rules = cloneDeep(this.rulesData[action]);
        delete ruleObject.rules.rules.id;
        ruleObject.rules.rules.type = 'condition';
        ruleObject.rules.rules.lineNumber = 1;
        ruleObject.rules.entityType = this.entityType;

        rules.push(ruleObject);
      }

      return rules;
    },
    updateRules() {
      if (Object.keys(this.rulesData).length) {
        this.handleUpdateRules({updateRuleRequestArray: this.getRules()})
          .then(() => {
            this.$emit('isRulesDataSaved', true)
            this.isRulesDataSaved = true;
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.edit-rules {
  .card-header {
    background-color: transparent;
  }
  .form-content {
    background: none;
    .card.zq--wizard-card {
      background-color: #fff;
    }
  }
  .rules-header-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding-top: 1.25rem;
    &--left {
      display: flex;
      @include media-breakpoint-down('635px') {
        margin-bottom: 1rem;
      }
      @include media-breakpoint-down('576px') {
        .rules-drop-select {
          width: 200px;
        }
      }
      @include media-breakpoint-down('300px') {
        flex-wrap: wrap;
        justify-content: flex-end;
        .rules-drop-select {
          width: 100%;
          margin-bottom: .5rem;
        }
      }
    }
    .rule-set {
      flex: 1
    }
    .rule-actions {
      flex: 3;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      //margin-bottom: 1rem;
    }
  }
  .field-paper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background: grey;
    opacity: 0.1;
  }
  .rules-drop-select {
    width: 220px;
    margin-right: 1rem;
  }
  .zq--wizard-card {
    .card-body {
      border-top: none;
      overflow: visible;
    }
  }
  .tabs-component-tab {
    margin-bottom: -1px;
  }
}

// SVG WITH STROKE
.rules-expand-btn {
  svg {
    stroke: #636f83;
  }
  &:hover {
    svg {
      stroke: #FFFFFF;
    }
  }
}

.rules-engine {
  input {
    background-color: transparent;
  }
  .vs__dropdown-menu {
    min-width: 280px !important;
  }
}
</style>
